import { Main1Top } from '../../styles/Web/Main/main1top_styled'
import { MainLayout } from '../../styles/Web/Main/styled'
import autocallLogo from 'assets/img/mainbanner/autocall_logo.png'
const Main1TopSection = () => {
  return (
    <Main1Top>
      <MainLayout>
        <div className='banner-top'>
          <div className='banner_contents'>
            <p>내 번호!</p>
            <img src={autocallLogo} alt='autocall logo img' />
            <div className='app_store'>
              <a
                href='https://play.google.com/store/apps/details?id=kr.co.datau.ai.autocall&hl=ko'
                target='_blank'
                rel='noreferrer'
              >
                <span className='googleplay' />
              </a>
              {/* <Link to={"/"}>
                <span className={"appstore"}></span>
              </Link> */}
            </div>
          </div>
        </div>
      </MainLayout>
    </Main1Top>
  )
}

export default Main1TopSection
