import styled from 'styled-components'
import STYLE_GUIDE from '../../../service/constants/styleGuide'

export const MainLayout = styled.div`
   {
    @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      margin: 0 auto;
      width: ${STYLE_GUIDE.SIZE.maxMainPageSize};
    }
    @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      margin: 0 20px;
    }
  }
`
