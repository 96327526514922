import useScroll from 'hooks/useScroll.hook'
import { NavLink, useLocation } from 'react-router-dom'
import mypage_icon from 'assets/img/floatingBtn/contact_icon.png'
import sticker_icon from 'assets/img/floatingBtn/sticker_icon.png'
import usage_icon from 'assets/img/floatingBtn/usage_icon.png'
import mo_float_open_btn from 'assets/img/floatingBtn/mo_autocall_float_btn.png'
import apart_icon from 'assets/img/mainbanner/quick_apart_icon.png'
import inquiry_icon from 'assets/img/mainbanner/quick_inquiry_icon.png'
import mo_playstore_logo from 'assets/img/mainbanner/playstore_logo.png'
import apart_pdf from 'assets/document/apartment.pdf'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { mofloatState } from '../../../recoil/common/atom'

const FloatingBtn = () => {
  const scroll = useScroll()
  const { pathname } = useLocation()
  const [mofloat, setMofloat] = useRecoilState(mofloatState)
  const [moscroll, setmoScroll] = useState(false)
  const handleClick = () => {
   const b2bUrl = ():string => {
    switch (true) {
     case window.location.href.includes('localhost'):
       return 'http://localhost:3000/b2b'
     case window.location.href.includes('dev'):
       return `https://dev-www.autocall.ai/b2b`
     default: // www.autocall.ai
       return `https://www.autocall.ai/b2b`
     }
   }
   window.open(b2bUrl(), 'newTab')
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setmoScroll(true)
      } else {
        setmoScroll(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll) // clean up
    }
  }, [setmoScroll])
  return (
    <>
      <div
        className={`floating-btn-container ${mofloat && 'active'}`}
        onClick={() => {
          mofloat && setMofloat(false)
        }}
      >
        <div className='floating-btn-top'>
          {mofloat && (
            <div className='mobile'>
              <NavLink
                to='usage'
                className='top'
                onClick={() => {
                  setMofloat(false)
                }}
              >
                <i>
                  <img src={usage_icon} alt='usage icon' />
                </i>
                <br />
                이용안내
              </NavLink>
              <div className='divider' />
              <NavLink
                to='mypage'
                onClick={() => {
                  setMofloat(false)
                }}
              >
                <i>
                  <img src={mypage_icon} alt='contact icon' />
                </i>
                <br />
                연락처 추가/변경
              </NavLink>
              <div className='divider' />
              <NavLink
                to='sticker'
                onClick={() => {
                  setMofloat(false)
                }}
              >
                <i>
                  <img src={sticker_icon} alt='sticker icon' />
                </i>
                <br />
                스티커 구매
              </NavLink>
              <div className='divider' />
              <NavLink
                to=''
                onClick={() => {
                  window.open(`${apart_pdf}`)
                  setMofloat(false)
                }}
              >
                <i>
                  <img src={apart_icon} alt='pdf icon' />
                </i>
                <br />
                아파트 제휴 안내
              </NavLink>
              <div className='divider' />
              <NavLink to='/b2b'>
                <i>
                  <img src={inquiry_icon} alt='? icon' />
                </i>
                <br />
                아파트 제휴 문의
              </NavLink>
            </div>
          )}
          <div
            className={`before_open ${mofloat && 'active'}`}
            onClick={() => {
              setMofloat((prev) => !prev)
            }}
          >
            <img src={mo_float_open_btn} alt='float_open' />
          </div>
          <label htmlFor='quickMenu'>
            <div className='quick-menu'>
              <span className='text'>
                Quick
                <br />
                Menu
              </span>
            </div>
          </label>
          <div className='pc'>
            <div className='btn first'>
              <a href={apart_pdf} target='_blank' rel='noreferrer'>
                <div className='icon'>
                  <span className='first' />
                </div>
                아파트 <br />
                제휴 안내
              </a>
            </div>
            <div className='btn second' onClick={handleClick}>
              <div className='icon'>
                <span className='second' />
              </div>
              <div className='text'>
                아파트
                <br />
                제휴 문의
              </div>
            </div>
          </div>
        </div>
        <div className='floating-btn-bot' onClick={() => scroll.handleTop()}>
          <div className='content'>
            <span className='upimg' />
          </div>
        </div>
      </div>
      {moscroll && pathname === '/' && (
        <div
          className='mo_floating_btn'
          onClick={() => window.open('https://play.google.com/store/apps/details?id=kr.co.datau.ai.autocall&hl=ko')}
        >
          <section>
            <div>
              <img
                src={mo_playstore_logo}
                alt='float_btn_logo'
                // onClick={() =>
                //   window.open("https://play.google.com/store/apps/details?id=kr.co.datau.ai.autocall&hl=ko")
                // }
              />
              {/* <img src={mo_appstore_logo} alt="float_btn_logo" /> */}
            </div>
            <p>앱 다운로드</p>
            {/* <a */}
            {/*    href={""} */}
            {/*    target={"_blank"} */}
            {/*    rel="noreferrer" */}
            {/* > */}
          </section>
        </div>
      )}
    </>
  )
}

export default FloatingBtn
