import Main1TopSection from 'components/1_Home/Main1TopSection'
import Main2MenuSection from '../../components/1_Home/Main2MenuSection'
import Main4HowToSection from '../../components/1_Home/Main4HowToSection'
import Main5JoinSection from 'components/1_Home/Main5JoinSection'
import Main7VideoSection from 'components/1_Home/Main7VideoSection'
import Main6ApartSection from 'components/1_Home/Main6ApartSection'
import Main3DescSection from 'components/1_Home/Main3DescSection'
import Popup from '../../components/Common/Popup'

const Home = () => {
    /*20231024 lgu+ 가입/해지 불가 모달*/
    /*useServiceModal(dayjs(SERVICE_DATE.MAIN_START_DT), dayjs(SERVICE_DATE.END_DT), true)*/
    return (
    <>
      <Popup />
      <Main1TopSection />
      <>
        <Main2MenuSection />
        {/* 운전자, 호출자 */}
        {/* 자동차, 신호등 */}
        <Main3DescSection />
        <Main4HowToSection />
        {/* 서비스 가입 */}
        <Main5JoinSection />
        {/* 아파트 제휴 */}
        <Main6ApartSection />
        {/* 오토콜 홍보영상 */}
        <Main7VideoSection />
      </>
    </>
  )
}

export default Home
