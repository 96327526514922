import styled from 'styled-components'
import STYLE_GUIDE from '../../../service/constants/styleGuide'
import background_img from '../../../assets/img/mainbanner/main1top_bg.png'
import mobile_background_img from '../../../assets/img/mainbanner/main1top_mo_bg.png'
import google_logo from 'assets/img/mainbanner/googleplay_logo.png'
import appstore_logo from 'assets/img/mainbanner/appstore_logo.png'
import google_logo_hover from 'assets/img/mainbanner/googleplay_logo_hover.png'
import appstore_logo_hover from 'assets/img/mainbanner/appstore_logo_hover.png'
export const Main1Top = styled.div`
   {
    @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      margin: 0 auto;
      width: 100%;
      background-color: #fafafa;
      background-image: url(${background_img});
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
      height: 840px;
      .banner-top {
        height: 840px;
        width: 100%;
        margin: auto 0;
        .banner_contents {
          width: 50%;
          padding: 240px 0 286px 0;
          > p {
            font-family: "GmarketSansBold", serif;
            font-weight: 500;
            font-size: 50px;
            line-height: 62px;
            margin: 0 0 14px 0;
            color: #2B3339;
            &:before {
              content: "지키자, ";
              color: ${STYLE_GUIDE.COLOR.main01};
            }
          }
          > img {
            width: 395px;
            height: 97px;
            margin-bottom: 70px;
          }
        }
        .app_store {
          display: flex;
          width: auto;
          > a {
            border-radius: 10px;
            width: 200px;
            height: 66px;
            line-height: 66px;
            background: #ffffff;
            border: 1px solid #dbe3e4;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
            margin-right: 14px;
            .appstore {
              background-image: url(${appstore_logo});
            }
            .googleplay {
              background-image: url(${google_logo});
            }

            > span {
              background-repeat: no-repeat;
              background-position: 50% 50%;
              background-size: contain;
              display: flex;
              margin: 0 auto;
              width: 123px;
              padding: 19.5px 0;
              height: 66px;
            }
          }
          > a:hover {
            background-color: #12124f;
            .appstore {
              background-image: url(${appstore_logo_hover});
            }
            .googleplay {
              //line-height: 66px;
              background-image: url(${google_logo_hover});
            }
          }
        }
      }
    }
    @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      margin: 0 auto;
      background-color: #fafafa;
      background-image: url(${mobile_background_img});
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
      height: 580px;
      width: 100%;
      .banner-top {
        height: auto;
        width: 100%;
        .banner_contents {
          width: 100%;
          > p {
            font-family: "GmarketSansBold", serif;
            font-weight: 500;
            font-size: 28px;
            line-height: 30px;
            margin: 44px auto 0 auto;
            text-align: center;
            color: #2B3339;
            &:before {
              content: "지키자, ";
              color: ${STYLE_GUIDE.COLOR.main01};
            }
          }
          > img {
            display: block;
            margin: 16px auto 34px auto;
            width: 218px;
            height: 54px;
          }
          .app_store {
            display: flex;
            margin: 0 auto;
            width: 288px;
            height: 46px;
            > a {
              margin: 0 auto;
              border-radius: 10px;
              width: 138px;
              line-height: 46px;
              background: #ffffff;
              border: 1px solid #dbe3e4;
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
              height: 100%;
              //margin: 0 7px;
              > span {
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: contain;
                display: flex;
                margin: 0 auto;
                width: 90%;
                height: 100%;
              }
              .appstore {
                background-image: url(${appstore_logo});
                background-size: 80%;
              }
              .googleplay {
                background-size: 80%;
                background-image: url(${google_logo});
              }
            }
            > a:hover {
              background-color: #12124f;
              .appstore {
                background-image: url(${appstore_logo_hover});
              }
              .googleplay {
                background-image: url(${google_logo_hover});
              }
            }
          }
        }
      }
    }
  }
`
