import styled from 'styled-components'
import img1 from 'assets/img/usage/ico_num1_black_30.png'
import img2 from 'assets/img/usage/ico_num2_black_30.png'
import img3 from 'assets/img/usage/ico_num3_black_30.png'
import STYLE_GUIDE from 'service/constants/styleGuide'

const HowToCallARSContainer = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    display: flex;
    justify-content: space-between;
    > div {
      width: 47.8%;
      > section {
        width: 100%;
        background: #f9fbfb;
        margin-bottom: 190px;
        border-radius: 20px;
        display: inline-block;
        > h1 {
          margin: 50px 0 30px 47px;
          font-family: GmarketSansBold, serif;
          font-weight: 500;
          font-size: 26px;
          line-height: 30px;
        }
        > div {
          margin-left: 47px;
          height: 142px;
          > section {
            display: flex;
            > section {
              display: block;
              .line {
                margin: 2px 14px;
                position: sticky;
                border-left: 2px dashed #ced6d8;
                height: 90px;
                transform: rotate(0deg);
              }
              > img {
                width: 30px;
                height: 30px;
              }
            }
            > div {
              margin: 0 0 0 14px;
              > p {
                margin: 5px 0;
                font-family: GmarketSansMedium, serif;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
              }
              .pc_text {
                transform: rotate(0deg);
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                color: ${STYLE_GUIDE.COLOR.grey02};
              }
              .mo_text {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    display: flex;
    flex-direction: column;
    > div {
      width: 320px;
      margin: 0 auto;
      > section {
        border-radius: 30px;
        background: #f9fbfb;
        padding: 30px 0;
        margin-bottom: 30px;
        > h1 {
          margin: 0px 0 18px 30px;
          font-weight: 500;
          font-size: 22px;
          line-height: 28px;
          font-family: GmarketSansBold, serif;
          color: ${STYLE_GUIDE.COLOR.grey01};
        }
        > div {
          > section {
            display: flex;
            > section {
              margin-left: 30px;
              .line {
                margin: 2px 14px;
                position: sticky;
                border-left: 2px dashed #ced6d8;
                height: 90px;
                transform: rotate(0deg);
              }
              > img {
                display: flex;
                margin: 0 auto;
                width: 24px;
                height: 24px;
              }
            }
            > div {
              > p {
                margin: 0 0 4px 10px;
                font-family: GmarketSansMedium, serif;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
              }
              .mo_text {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: ${STYLE_GUIDE.COLOR.grey02};
                margin-left: 10px;
                display: inline-block;
              }
              .pc_text {
                display: none;
              }
            }
          }
        }
      }
    }
  }
`

const HowToCallARS = () => {
  const caller = {
    title: '호출자',
    contents: [
      {
        img: <img src={img1} alt='num img' />,
        subitle: <p>1566-8382</p>,
        text_pc: (
          <span className='pc_text'>
            1566-5382로 전화 연결 후, ARS 안내에
            <br /> 따라 호출할 차량번호를 입력하세요.
          </span>
        ),
        text_mo: (
          <span className='mo_text'>
            1566-5382로 전화 연결 후, <br />
            ARS 안내에 따라 호출할 차량번호를 <br /> 입력하세요.
          </span>
        )
      },
      {
        img: <img src={img2} alt='num img' />,
        subitle: <p>호출 요청</p>,
        text_pc: (
          <span className='pc_text'>
            차량번호 확인 후 '호출 요청'을 완료하면
            <br /> 통화가 종료됩니다.
          </span>
        ),
        text_mo: (
          <span className='mo_text'>
            차량번호 확인 후 '호출 요청'을 <br /> 완료하면 통화가 종료됩니다.
          </span>
        )
      },
      {
        img: <img src={img3} alt='num img' />,
        subitle: <p>호출 결과 안내</p>,
        text_pc: (
          <span className='pc_text'>
            오토콜이 호출 결과를 휴대폰 문자메시지로
            <br /> 전달드리니 잠시 기다려 주세요.
          </span>
        ),
        text_mo: (
          <span className='mo_text'>
            오토콜이 호출 결과를 <br />
            휴대폰 문자메시지로 전달드리니 <br />
            잠시 기다려 주세요.
          </span>
        )
      }
    ]
  }
  const driver = {
    title: '운전자 (오토콜 가입고객)',
    contents: [
      {
        img: <img src={img1} alt='num img' />,
        subitle: <p>1566-5382 부착</p>,
        text_pc: (
          <span className='pc_text'>
            1566-5382 번호가 잘 보이도록
            <br /> 차량 전면에 부착하세요.
          </span>
        ),
        text_mo: (
          <span className='mo_text'>
            1566-5382 번호가 잘 보이도록
            <br /> 차량 전면에 부착하세요.
          </span>
        )
      },
      {
        img: <img src={img2} alt='num img' />,
        subitle: <p>오토콜 호출전화 수신</p>,
        text_pc: (
          <span className='pc_text'>
            1566-5382 '오토콜'로 걸려오는 전화를
            <br /> 받아 주세요.
          </span>
        ),
        text_mo: (
          <span className='mo_text'>
            1566-5382 '오토콜'로 걸려오는 <br /> 전화를 받아 주세요.
          </span>
        )
      },
      {
        img: <img src={img3} alt='num img' />,
        subitle: <p>호출 요청 확인</p>,
        text_pc: (
          <span className='pc_text'>
            오토콜 ARS를 통해 호출 요청을 전달받으면,
            <br /> 차량으로 이동해 주세요.
          </span>
        ),
        text_mo: (
          <span className='mo_text'>
            오토콜 ARS를 통해 <br /> 호출 요청을 전달받으면, 차량으로 <br />
            이동해 주세요.
          </span>
        )
      }
    ]
  }

  return (
    <HowToCallARSContainer>
      <div>
        <section>
          <h1>{caller.title}</h1>
          {caller.contents.map((option, index) => (
            <div key={index}>
              <section>
                <section>
                  {option.img}
                  {index !== 2 && <p className='line' />}
                </section>
                <div>
                  {option.subitle}
                  {option.text_pc}
                  {option.text_mo}
                </div>
              </section>
            </div>
          ))}
        </section>
      </div>
      <div>
        <section>
          <h1>{driver.title}</h1>
          {driver.contents.map((option, index) => (
            <div key={index}>
              <section>
                <section>
                  {option.img}
                  {index !== 2 && <p className='line' />}
                </section>
                <div>
                  {option.subitle}
                  {option.text_pc}
                  {option.text_mo}
                </div>
              </section>
            </div>
          ))}
        </section>
      </div>
    </HowToCallARSContainer>
  )
}

export default HowToCallARS
