import { MainLayout } from '../../styles/Web/Main/styled'
import MainService1Section from './Main3DescSection/MainService1Section'
import MainService2Section from './Main3DescSection/MainService2Section'
import MainService3Section from './Main3DescSection/MainService3Section'
import MainService4Section from './Main3DescSection/MainService4Section'

const Main3DescSection = () => {
  return (
    <MainLayout>
      <MainService1Section />
      <MainService2Section />
      <MainService3Section />
      <MainService4Section />
    </MainLayout>
  )
}
export default Main3DescSection
