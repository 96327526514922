const STYLE_GUIDE = {
  GNB: {
    margin: 80
  },
  COLOR: {
    main01: '#10A8CA',
    sub01: '#4CAEF5',
    sub02: '#1556D4',
    sub03: '#12124F',
    sub04: '#614AED',
    sub05: '#7FC6DA',
    sub06: '#C2E7F2',
    sub07: '#CAF5FF',
    sub08: '#EFF8FB',
    grey01: '#2B3639',
    grey02: '#7D898B',
    grey03: '#A8B1B2',
    grey04: '#BDC6C8',
    grey05: '#DBE3E4',
    grey06: '#F6F8F9',
    white: '#FFFFFF',
    warning: '#FF6E65',
    footer: '#F0F2F4',
    footer2: '#E5E9EC',
    footer3: '#545467',
    footer4: '#6D7C92'
  },
  SIZE: {
    maxMobileSize: '1200px',
    maxMainPageSize: '1100px',
    minMobileSize: '360px',
    minContentsLayoutWidth: '1200px',
    pageWidth: '940px'
  }
}
export default STYLE_GUIDE
