import STYLE_GUIDE from '../../../service/constants/styleGuide'
import styled from 'styled-components'

export const Main3Desc = styled.div`
   {
    @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      .mobile_waring {
        display: none;
      }
      width: 100%;
      .section_title {
        margin: 245px 0 18px 0;
        text-align: center;
        font-weight: 700;
        font-size: 32px;
        line-height: 32px;
        color: ${STYLE_GUIDE.COLOR.grey02};
      }

      .section_desc {
        margin: 0;
        font-family: "GmarketSansBold", serif;
        font-style: normal;
        font-weight: 500;
        font-size: 50px;
        line-height: 62px;
        text-align: center;
        color: ${STYLE_GUIDE.COLOR.grey01};
        .underline {
          font-weight: 500;
          font-size: 50px;
          line-height: 62px;
          font-family: GmarketSansBold, serif;
          background: linear-gradient(0deg, #caf5ff 30%, transparent 50%);
        }
        &.service1_mo {
          display: none;
        }
        &.service2 {
          text-align: left;
          margin-top: 175px;
          > div {
            display: flex;
            > p {
              font-weight: 500;
              font-size: 50px;
              line-height: 62px;
              font-family: GmarketSansBold, serif;
              margin: 0;
            }
            .color_light {
              margin: auto 17px auto 0;
              width: auto;
              height: 118px;
            }
          }
        }
        &.service3 {
          text-align: left;
          margin-top: 175px;
          > div {
            display: flex;
            > p {
              font-weight: 500;
              font-size: 50px;
              line-height: 62px;
              font-family: GmarketSansBold, serif;
              margin: 0;
            }
            .color_light {
              margin: auto 17px auto 0;
              width: auto;
              height: 118px;
            }
          }
        }
        &.service4 {
          text-align: left;
          margin-top: 175px;
          > div {
            display: flex;
            > p {
              font-weight: 500;
              font-size: 50px;
              line-height: 62px;
              font-family: GmarketSansBold, serif;
              margin: 0;
            }
            .color_light {
              margin: auto 16px auto 0;
              width: auto;
              height: 118px;
            }
          }
        }
      }
      > img {
        display: flex;
        width: 1060px;
        height: 539px;
        margin: 63px auto 278px auto;
      }
      .main-service_content {
        display: flex;
        width: 100%;
        &.service2 {
          margin-bottom: 230px;
          height: 634px;
          .text-content {
          }
          > section {
            height: 634px;
            width: 50%;
            .mo {
              display: none;
            }
            > img {
              width: 100%;
              height: 634px;
            }
            > div {
              > span {
                font-weight: 700;
                font-size: 20px;
                line-height: 32px;
              }
              > p {
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                color: ${STYLE_GUIDE.COLOR.grey02};
              }
              margin-top: 70px;
            }
          }
        }
        &.service3 {
          height: 480px;
          margin-bottom: 230px;
          > section {
            height: 480px;
            width: 50%;
            .mo {
              display: none;
            }
            > img {
              width: 550px;
              height: 500px;
            }

            > div {
              margin-top: 43px;
              > span {
                font-weight: 700;
                font-size: 20px;
                line-height: 32px;
              }
              > p {
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                color: ${STYLE_GUIDE.COLOR.grey02};
              }
            }
            .mobile_waring {
              display: none;
            }
            .warning_message {
              display: flex;
              width: 100%;
              height: 100px;
              background-color: #ecfcff;
              border-radius: 30px;
              > img {
                margin: 29px 14px 33px 30px;
                width: 42px;
                height: 38px;
              }
              > p {
                margin: 24px 0;
                > span {
                  font-weight: 700;
                  font-size: 18px;
                  line-height: 26px;

                  &:before {
                    content: "1566-5382 ";
                    color: ${STYLE_GUIDE.COLOR.main01};
                  }
                }
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: ${STYLE_GUIDE.COLOR.grey02};
              }
            }
          }
        }
        &.service4 {
          height: 536px;
          margin-bottom: 146px;
          .text-content {
          }
          > section {
            height: 536px;
            width: 50%;
            > img {
              width: 100%;
              height: 536px;
            }
            > div {
              > span {
                font-weight: 700;
                font-size: 20px;
                line-height: 32px;
              }
              > p {
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                color: ${STYLE_GUIDE.COLOR.grey02};
              }
              margin-top: 70px;
            }
          }
        }
      }
      .mobile-car {
        display: none;
      }
    }
    ///////////////////////////////////////////////
    //mobile
    //////////////////////////////////////////////////////
    @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      margin: 0 auto;
      width: 320px;

      .pc-car {
        display: none;
      }
      .section_title {
        margin-top: 80px;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: ${STYLE_GUIDE.COLOR.grey02};
      }
      .section_desc {
        &.service1_pc {
          display: none;
        }
        &.service1_mo {
          margin: 0 auto;
          text-align: center;
          font-family: GmarketSansBold, serif;
          font-weight: 500;
          font-size: 32px;
          line-height: 48px;
          .underline {
            margin: 30px;
            background: linear-gradient(0deg, #caf5ff 30%, transparent 50%);
            font-weight: 500;
            font-size: 32px;
            line-height: 48px;
            font-family: GmarketSansBold, serif;
          }
        }
        &.service2 {
          .underline {
            background: linear-gradient(0deg, #caf5ff 30%, transparent 50%);
            font-weight: 500;
            font-size: 32px;
            line-height: 48px;
            font-family: GmarketSansBold, serif;
          }
          .color_light {
            height: 100px;
          }
          > div {
            > p {
              font-family: GmarketSansBold, serif;
              font-weight: 500;
              font-size: 32px;
              line-height: 48px;
              margin: 20px auto 40px auto;
              .underline {
                font-weight: 500;
                font-size: 32px;
                line-height: 48px;
                font-family: GmarketSansBold, serif;
                background: linear-gradient(0deg, #caf5ff 30%, transparent 50%);
              }
            }
          }
        }
        &.service3 {
          > div {
            .underline {
              font-weight: 500;
              font-size: 32px;
              line-height: 48px;
              font-family: GmarketSansBold, serif;
              background: linear-gradient(0deg, #caf5ff 30%, transparent 50%);
            }
            .color_light {
              height: 100px;
            }
            > p {
              font-family: GmarketSansBold, serif;
              font-weight: 500;
              font-size: 32px;
              line-height: 48px;
            }
          }
        }
        &.service4 {
          .color_light {
            height: 100px;
          }
          > div {
            .underline {
              font-weight: 500;
              font-size: 32px;
              line-height: 48px;
              font-family: GmarketSansBold, serif;
              background: linear-gradient(0deg, #caf5ff 30%, transparent 50%);
            }
            > p {
              font-family: GmarketSansBold, serif;
              font-weight: 500;
              font-size: 32px;
              line-height: 48px;
              margin: 20px auto 40px auto;
            }
          }
        }
      }
      .mobile-car {
        display: flex;
        margin: 66px auto 150px -20px;
        width: 360px;
        height: 183px;
      }
      .main-service_content {
        &.service2 {
          margin: 0 auto;

          > section {
            .pc {
              display: none;
            }
            > img {
              width: 320px;
              height: 494px;
              margin-top: 40px;
              margin-bottom: 100px;
            }
            > div {
              > span {
                font-weight: 700;
                font-size: 18px;
                line-height: 30px;
                //color: blue;
              }
              > p {
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                color: ${STYLE_GUIDE.COLOR.grey02};
              }
            }
          }
        }
        &.service3 {
          display: flex;
          flex-direction: column-reverse;
          > section {
            .pc {
              display: none;
            }
            > div {
              > span {
                font-weight: 700;
                font-size: 18px;
                line-height: 30px;
              }
              > p {
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                color: ${STYLE_GUIDE.COLOR.grey02};
              }
            }
            > img {
              margin: 40px 0;
              width: 320px;
              height: 330px;
            }
          }
        }
        &.service4 {
          > section {
            > img {
              margin-top: 40px;
              margin-bottom: 110px;
              width: 320px;
              height: 320px;
            }
            > div {
              > span {
                font-weight: 700;
                font-size: 18px;
                line-height: 30px;
              }
              > p {
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                color: ${STYLE_GUIDE.COLOR.grey02};
              }
            }
          }
        }
        .mobile_waring {
          width: 100%;
          background: #ecfcff;
          border-radius: 30px;
          margin-bottom: 100px;
          > img {
            margin: 30px auto 20px auto;
            display: flex;
            width: 42px;
            height: 38px;
          }
          > p {
            text-align: center;
            > p {
              margin: 10px 0 30px 0;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: ${STYLE_GUIDE.COLOR.grey02};
            }
            > span {
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;
              color: ${STYLE_GUIDE.COLOR.grey02};
              &:before {
                content: "1566-5382 ";
                color: ${STYLE_GUIDE.COLOR.main01};
              }
            }
          }
        }
        .warning_message {
          display: none;
        }
      }
    }
  }
`
