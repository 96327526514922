import { useNavigate} from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { otpAuthType, otpjoinready, otpTimeOut, otpTransctionID } from 'recoil/otp/atom'
import {
  getReqCertDelete,
  getReqCertJoinReady,
  getReqCertJoinPartner,
  getReqCertLogin,
  getReqSendOTP,
  otpWithSendReset,
  getReqAddUserOTP
} from 'recoil/otp/withSendAndCert'
import { resLoginUser } from 'recoil/user/atom'
import { btnDisabled, inputDisabled, validateTypeFam } from 'recoil/validate/atom'
import { CMD_TYPE, TELECOM } from 'service/constants/common'
import useAPI from './useAPI.hook'
import useCookies from './useCookies.hook'
import useLoading from './useLoading.hook'
import useModal from './useModal.hook'
import {RefreshRecapAuthToken} from "../recoil/common/atom";

export const useSendOTP = () => {
  const reqSendOTP = useRecoilValue(getReqSendOTP)
  const reqAddUserSendOTP = useRecoilValue(getReqAddUserOTP)
  const setOtpTimeOut = useSetRecoilState(otpTimeOut)
  const setOtpTransactionID = useSetRecoilState(otpTransctionID)
  const authType = useRecoilValue(otpAuthType)
  const loading = useLoading()
  const api = useAPI()
  const certBtnDisabled = useSetRecoilState(btnDisabled('certBtn'))
  const telDisabled = useSetRecoilState(inputDisabled('telecom'))
  const setRefreshReCaptcha = useSetRecoilState(RefreshRecapAuthToken)
  async function sendOTP () {
    loading.on()
    authType ==='login' && setRefreshReCaptcha((r:boolean) => !r);
    const result = await api.requestSRS<ResponseSendOTPBody>(
      CMD_TYPE.SEND_OTP,
      authType === 'fadd' ? reqAddUserSendOTP : reqSendOTP
    )
    if (result) {
      if (typeof result === 'string') {
        if (result === '030005') {
          telDisabled({ isDisabled: true })
          certBtnDisabled({ isDisabled: false })
          loading.off()
          return true
        }
      }
      setOtpTimeOut(result.TimeOut)
      setOtpTransactionID(result.TransactionID)
      telDisabled({ isDisabled: true })
      certBtnDisabled({ isDisabled: false })
      loading.off()
      return true
    } else {
      loading.off()
      return false
    }
  }

  return {
    request: sendOTP
  }
}

export const useCertOTP = () => {
  const loading = useLoading()
  const reqCertJoinPartner = useRecoilValue(getReqCertJoinPartner)
  const reqCertJoinReady = useRecoilValue(getReqCertJoinReady)
  const reqCertLogin = useRecoilValue(getReqCertLogin)
  const reqCertDel = useRecoilValue(getReqCertDelete)
  const setLogin = useSetRecoilState(resLoginUser)
  const resetSendOTP = useSetRecoilState(otpWithSendReset)
  const setTermsValid = useSetRecoilState(validateTypeFam('terms'))
  const setJoinCheck = useSetRecoilState(otpjoinready)
  const cookie = useCookies()
  const api = useAPI()

  const modal = useModal()
  const navigate = useNavigate()

  function join_complete_message(isMoveMyPage: boolean) {
    if (isMoveMyPage) {
      modal.confirm({
        title: '서비스 가입이 완료되었습니다',
        contents: `마이페이지에서 추가연락처,
          추가 차량을 등록하실 수 있습니다.`,
        onCancel: () => {
          resetSendOTP((cur) => cur)
          navigate('/')
        },
        onConfirm: () => {
          resetSendOTP((cur) => cur)
          navigate('/mypage')
        }
      })
    } else {
      modal.alert({
        title: '서비스 가입이 완료되었습니다',
        contents: '오토콜의 다양한 서비스를 이용해 보세요.',
        onCancel: () => {
          resetSendOTP((cur) => cur)
          navigate('/')
        },
        onConfirm: () => {
          resetSendOTP((cur) => cur)
          navigate('/success')
        }
      })
    }
  }

  async function join_ready () {
    const result = await api.requestSRS<boolean>(CMD_TYPE.CERT_OTP, reqCertJoinReady)
    if (result) {
      loading.off()
      setJoinCheck(true)
      return true
    } else {
      loading.off()
      setJoinCheck(false)
      return false
    }
  }

  async function join_partner () {
    loading.on()
    if (reqCertJoinPartner.Telecom === TELECOM.KT) {
      if (
        !(
          reqCertJoinPartner.Terms1Agree === 1 &&
                    reqCertJoinPartner.Terms2Agree === 1 &&
                    reqCertJoinPartner.Terms4Agree === 1
        )
      ) {
        setTermsValid({ valid: true, msg: '210' })
        loading.off()
        return false
      } else {
        setTermsValid({ valid: false, msg: '' })
      }
      if (!(reqCertJoinPartner.Terms1Agree === 1 && reqCertJoinPartner.Terms2Agree === 1)) {
        loading.off()
        setTermsValid({ valid: true, msg: '210' })
        return false
      } else {
        setTermsValid({ valid: false, msg: '' })
      }
    }
    // console.log(reqCertJoinPartner)
    const result = await api.requestSRS<ResponseLoginBody>(CMD_TYPE.CERT_OTP, reqCertJoinPartner)
    if (result != null) {
      setLogin(result)
      cookie.login(result.PNumber, result.SessionID)
      loading.off()

        // 2024-08-07 LG U+ 가입완료 문구 추가
      if (reqCertJoinPartner.Telecom === TELECOM.LGU) {
        modal.alert({
          title: '[오토콜 부가서비스 가입안내]',
          contents: '휴대폰 번호 노출없이 주차한 차량에 대한 이동요청 알림을 받을 수 있는 개인정보 유출방지 유료부가서비스에 가입되셨습니다.',
          onCancel: () => {
          },
          onConfirm: () => {
            join_complete_message(result.IsFreeMember || (result.AlreadyRecvSticker ?? false))
          }
        })
      } else {
        join_complete_message(result.IsFreeMember || (result.AlreadyRecvSticker ?? false))
      }
      return true
    } else {
      loading.off()
      return false
    }
  }

  async function login () {
    loading.on()
    const result = await api.requestSRS<ResponseLoginBody>(CMD_TYPE.CERT_OTP, reqCertLogin)
    if (result && String(result)?.length) {
      setLogin(result)
      cookie.login(result.PNumber, result.SessionID)
      resetSendOTP((cur) => cur)
      loading.off()
      setTimeout(() => {
        window.location.reload()
      }, 50)
      return true
    } else {
      loading.off()
    }
  }

  async function cancel () {
    loading.on()
    const result = await api.requestSRS<unknown>(CMD_TYPE.CERT_OTP, reqCertDel)
    if (result) {
      cookie.logout()
      resetSendOTP((cur) => cur)
      loading.off()
      return true
    } else {
      loading.off()
      return false
    }
  }

  return {
    join_partner,
    join_ready,
    login,
    cancel
  }
}
